import React from 'react';
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import "./shared-styles.css";
const PrivacyPolicy = () => {
    const navigate = useNavigate();
    return (
        <div className='shared'>
            <button 
                className="back-buttons"
                onClick={() => navigate(-1)}
                aria-label="Go back"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                </svg>
            </button>
        <div className="legal-page-container">
            
            <h1>Privacy Policy</h1>
            <h3><strong>Last updated:</strong> December 31, 2024</h3>

            <p>
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your 
                information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>

            <p>
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the 
                collection and use of information in accordance with this Privacy Policy.
            </p>

            <h2>Interpretation and Definitions</h2>

            <h3>Interpretation</h3>
            <p>
                The words of which the initial letter is capitalized have meanings defined under the following conditions. 
                The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
                <li><strong>Account:</strong> A unique account created for You to access our Service or parts of our Service.</li>
                <li><strong>Affiliate:</strong> An entity that controls, is controlled by or is under common control with a party...</li>
                <li><strong>Company:</strong> Refers to Coder House.</li>
                <li><strong>Cookies:</strong> Small files placed on Your device by a website...</li>
                <li><strong>Country:</strong> Madhya Pradesh, India</li>
                <li><strong>Device:</strong> Any device that can access the Service...</li>
                <li><strong>Personal Data:</strong> Information that relates to an identified or identifiable individual.</li>
                <li><strong>Service:</strong> Refers to the Website.</li>
                <li><strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company...</li>
                <li><strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Service...</li>
                <li><strong>Website:</strong> Refers to Coder House, accessible from <a href="https://www.coderhouse.in">https://www.coderhouse.in</a>.</li>
                <li><strong>You:</strong> The individual accessing or using the Service...</li>
            </ul>

            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>

            <h4>Personal Data</h4>
            <p>
                While using Our Service, We may ask You to provide Us with certain personally identifiable information...
            </p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Usage Data</li>
            </ul>

            <h4>Usage Data</h4>
            <p>
                Usage Data is collected automatically when using the Service. Usage Data may include information such as...
            </p>

            <h3>Tracking Technologies and Cookies</h3>
            <p>
                We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information...
            </p>

            <h4>Types of Cookies We Use:</h4>
            <ul>
                <li><strong>Necessary / Essential Cookies:</strong> These Cookies are essential to provide You with services available...</li>
                <li><strong>Cookies Policy / Notice Acceptance Cookies:</strong> These Cookies identify if users have accepted the use of cookies...</li>
                <li><strong>Functionality Cookies:</strong> These Cookies allow us to remember choices You make when You use the Website...</li>
            </ul>

            <h2>Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
                <li>To provide and maintain our Service...</li>
                <li>To manage Your Account...</li>
                <li>To contact You...</li>
                <li>To provide You with news, special offers...</li>
                <li>To manage Your requests...</li>
                <li>For business transfers...</li>
                <li>For other purposes such as data analysis...</li>
            </ul>

            <h2>Retention of Your Personal Data</h2>
            <p>
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy...
            </p>

            <h2>Transfer of Your Personal Data</h2>
            <p>
                Your information, including Personal Data, is processed at the Company's operating offices and in any other places...
            </p>

            <h2>Delete Your Personal Data</h2>
            <p>
                You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You...
            </p>

            <h2>Disclosure of Your Personal Data</h2>
            <p>The Company may disclose Your Personal Data under the following conditions:</p>
            <ul>
                <li><strong>Business Transactions:</strong> If the Company is involved in a merger, acquisition...</li>
                <li><strong>Law Enforcement:</strong> Under certain circumstances...</li>
                <li><strong>Other legal requirements:</strong> To comply with legal obligations, protect and defend...</li>
            </ul>

            <h2>Security of Your Personal Data</h2>
            <p>
                The security of Your Personal Data is important to Us, but remember that no method of transmission...
            </p>

            <h2>Children's Privacy</h2>
            <p>
                Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information...
            </p>

            <h2>Links to Other Websites</h2>
            <p>
                Our Service may contain links to other websites that are not operated by Us. If You click on a third party link...
            </p>

            <h2>Changes to this Privacy Policy</h2>
            <p>
                We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy...
            </p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
                <li>By email: coderhouseoptus@gmail.com</li>
            </ul>
        </div>
        </div>
    );
};

export default PrivacyPolicy;